
import Layout from '@/layouts'

const categoryService = {
  path: '/ownerClinic',
  component: Layout,
  name: 'ownerClinic',
  redirect: '/ownerClinic/index',
  children: [
    {
      path: '/ownerClinic/index',
      component: () => import('@/views/ownerClinic/index'),
      name: 'ownerClinic.index',
      meta: { title: 'Партнер доктор'}
    },
  ]
}

export default categoryService
