import { index, indexLaboratory,color, show, store, update, destroy, chart, inventory, relationInventory, parentInventory, inventoryOnlyServiceName } from '../../../api/services';

export const actions = {

    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit("SET_LIST", res.data.data.services.data);
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.data.services.pagination.total });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    indexLaboratory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            indexLaboratory(params).then(res => {
                commit("SET_LIST_LABORATORY", res.data.data.services.data);
                // commit("UPDATE_PAGINATION", { key: 'total', value: res.data.data.services.pagination.total });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    color({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            color(params).then(res => {
                commit("SET_COLOR", res.data.data.services.data);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
          inventory(params)
            .then(response => {
              commit('SET_INVENTORY', response.data.data.services.data);
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
      },   

      inventoryOnlyServiceName({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventoryOnlyServiceName(params)
            .then(response => {
              commit('SET_INVENTORY_ONLY_SERVICE_NAME', response.data.data.services);
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data)//This is a comment wrote by Nafisa
            });                          // All bugs are Nafisa's 
        });
      },  

      parentInventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
          parentInventory(params)
            .then(response => {
              commit('SET_PARENT_INVENTORY', response.data.data.services.data);
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
      },   

    relationList({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            relationInventory(params).then(res => {
                commit("SET_RELATION_LIST", res.data.data.services);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    selectedRelationList({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit("SET_SELECTED_RELATION_LIST", res.data.data.services.data);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    chart({ commit }, type) {
        return new Promise((resolve, reject) => {
            chart({ type: type }).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },


    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id).then(res => {
                commit("SET_MODEL", res.data.data.service);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    setlist({ commit }, data) {
        commit("SET_SERVICES_LIST", data)
    },

    updateSetlist({ commit }, data) {
        commit("UPDATE_SET_SERVICES_LIST", data)
    },

    updateSelectedItem({ commit }, data) {
        console.log(data);
        commit("UPDATE_SERVICES_LIST", data)
    },

    removeSelectedList({ commit }, id) {
        commit("ROMEVE_SERVICES_ITEM", id)
    },

    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },

    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },

    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    emptyList({ commit }) {
        commit("EMPTY_SERVICES_LIST");
    },

}