import { columns } from './properties/columns'
import {model} from "./properties/model";
import {rules} from "./properties/rules";

export const state = {
    model: JSON.parse( JSON.stringify( model )),
    columns: columns,
    phone_numbers:[],
    rules: rules
};
