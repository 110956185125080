import { getOwnerClinic, store, update, destroy } from '../../../api/ownerClinic';

export const actions = {

    getOwnerClinic({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getOwnerClinic(params).then(res => {
                commit("SET_MODEL", res.data.data.owner);
                commit("SET_PHONE_NUMBERS", res.data.data.grouped_phone_numbers);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },


    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },
}
