export const ru_messages = {
  yes: "Да",
  no: "Нет",
  main: "Главная",
  yes_doctor: "Связь с доктором",
  social: "Откуда узнали",
  refresh: "Обновить",
  reset: "Сброс",
  delete_success: "Удаление завершено",
  delete_calcel: "Удаление отменено",
  delete_notification: "Это навсегда удалит файл. Продолжить?",
  warning: "Предупреждение",
  cancel: "Отмена",
  c_sum: "сум",
  test: "Тест",
  total: "Общая сумма",
  price: "Стоимость",
  measurement: "Измерение",
  cost_amount: "Сумма себестоимости",
  sale_amount: "Сумма продажи",
  name: "Наименование",
  available: "Доступно",
  code: "Код",
  date_0: "Дата",
  day: "День",
  warehouse: "Склад",
  confirmed: "Подтверждено",
  confirm: "Подтвердить",
  warehouses_0: "Склады",
  main_warehouses: "Главные склады",
  warehouse_movement: "Перемещение складов",
  date_movement: "Дата перемещения",
  show_drug: "Посмотреть лекарства",
  date: "Дата создания",
  update_date: "Дата изменения",
  rate: "Курс",
  search: "Найти",
  search_0: "Поиск",
  number: "Номер",
  day_in_warehouse: "Дней на складе",
  total_price: "Общая сумма",
  start_date: "от дата",
  end_date: "до дата",
  all_date: "Дата",
  from: "От",
  save_and_create_new: "Сохранить и добавить новую",
  for_bussiness: "Юридическое лицо",
  for_personal: "Физичесикое лицо",
  contact: "Контактное лицо",
  bank_account: "Расчетный счет",
  short_name: "Краткое наименование",
  full_name: "Полное наименование",
  phone_number: "Номер телефона",
  fax: "Факс",
  phone: "Телефон",
  email: "Электронная почта",
  email_address: "Email почта",
  address: "Адрес",
  legal_address: "Официальный адрес",
  inn: "ИНН",
  mfo: "МФО",
  onlis: "ОНЛИС",
  okonx: "ОКОНХ",
  oked: "ОКЕД",
  rkp_nds: "РКП НДС",
  nds: "НДС",
  sku: "СКУ",
  bank: "Банк",
  correspondent_account: "Кор. аккаунт",
  accaunt: "Аккаунт",
  position: "Должность",
  type: "Тип",
  lack_of: "Отсутствует",
  paid: "Оплачено",
  not_paid: "Не оплачено",
  number_card: "Номер амбулаторной карты",
  doctor: "Врач",
  doctor_0: "Доктор",
  doctor_name: "Доктор (фио)",
  pay: "Оплатить",
  payments_report: "Отчет платежей",
  enumeration: "Перечисление",
  terminal: "Терминал",
  cach: "Наличные",
  comment: "Комментарий",
  all_service: "Все услуги",
  show: "Показано",
  showing: "Просмотреть",
  website_name: "Укажите название веб-сайта",
  name_service: "Название услуги",
  provision: "Условие",
  status_condition: "Статус условия",
  to_history: "к истории",
  name_0: "Название",
  until: "До",
  show_0: "Показать",
  hidden: "Не указано",
  doctor_can: "Покажитесь своему врачу",
  color: "Цвет",
  aggrement: "Договора",
  date_aggrement: "Дата договора",
  term_aggrement: "Срок договора",
  price_aggrement: "Срок договора",
  order_amput: "Сумма обслуживания",
  deal_amount: "Сумма договора",
  remaind: "Остаток",
  back: "Возврат",
  balance: "Баланс",
  all_not_paid_price: "Общая сумма не оплачена",
  search_order: "Найти заказы",
  search_order0: "Найти заказ",
  pay_order: "Оплатить заказы",
  cost_order: "Цена заказа",
  not_paid_sum: "Неоплаченная сумма",
  payable_sum: "Оплачиваемая сумма",
  contractual_basis: "На договорной основе",
  view: "Вид",
  view_0: "Видеть",
  new_type_expence: "Новый тип расхода",
  update_type_expence: "Изменить тип расхода",
  new_expence: "Новый расход",
  type_expence: "Тип расхода",
  update_expence: "Изменить расход",
  active: "Активный",
  is_active: "Активен",
  npt_active: "Не активный",
  reverse: "Обратный",
  reverse_rate: "Обратный курс",
  symbol: "Символ",
  defects: "Дефекты",
  drug: "Лекарство",
  replacement: "Замена",
  templates: "Шаблоны",
  go_back: "Назад",
  fio: "ФИО",
  dob: "Дата Рождения",
  gender: "Пол",
  clear: "Очистить",
  save_and_go: "Сохранить и продолжить",
  next: "Следующий",
  update: "Изменить",
  return_material: "Возврат сырья",
  parent: "Родитель",
  parent_category: "Родительская категория",
  please_choose_parent: "Пожалуйста, выберите родительскую категорию",
  surname: "Фамилия",
  first_name: "Имя",
  patronymic: "Отчество",
  male: "Мужчина",
  female: "Женщина",
  born_place: "Место рождения",
  passport_number: "Номер паспорта",
  address_register: "Адрес регистрации",
  arrival_date: "Дата прибытия",
  departure_date: "Дата отъезда",
  order_number: "Номер заказа",
  rooms_number: "Номер комнаты",
  name_template: "Название шаблона",
  limit: "Лимит",
  payment_report_limit: "Лимит платежного отчета",

  pp: "П.П.",
  ifo: "И.Ф.О",
  service_name: "Название услуги",
  schedule: "Расписание",
  patient: "Пациент",
  patient_service: "Услуги пациента",
  patient_info: "Информация о пациенте",
  patient_0: "Пациент",
  patients: "Пациенты",
  reports: "Отчеты",
  cashbox: "Касса",
  warehouses: "Склады",
  actions: "Действия",
  settings: "Настройки",
  forms: "Формы (МКБ-10)",
  my_office: "Мой Кабинет",
  hospital: "Стационар",
  printing: "Печать",
  log_out: "Выйти",
  profile: "Профиль",
  print_form: "Печатная форма",
  forms_of_documents: "Формы документов",
  document_forms_editor: "Редактировать формы документов",
  source: "Источник",
  new_source: "Новый источник",
  update_source: "Изменить источник",
  tags: "Теги",
  new_tag: "Новый тег",
  update_tags: "Изменить тег",
  service_categoy: "Категория услуги",
  branches: "Филиалы",
  orders: "Заказы",
  order: "Заказ",
  conclusions_history: "История заключений",
  report_doctors: "Отчет-врачи",
  report_partner_doctors: "Отчет по партнёрам докторам",
  payments: "Платежи",
  contracts: "Договора",
  payment_reports: "Отчёты платежей",
  payments_for_expenses: "Платежи за расходы",
  currency: "Валюта",
  payment_type: "Тип оплаты",
  new_payment_type: "Новый тип оплаты",
  update_payment_type: "Изменить тип оплаты",
  flow_types: "Типы расхода",
  stasionary_cashier: "Касса стационара",
  users: "Пользователи",
  user: "Пользователь",
  roles: "Роли",
  role: "Роль",
  role_name: "Имя роли",
  update_role: "Изменить роль",
  new_role: "Новая роль",
  access_rights: "Права доступа",
  access_right: "Права доступа",
  update_access_rights: "Изменить права доступа",
  choose_status: "пожалуйста, выберите статус",
  print_audit: "Печатный аудит",
  show_audit: "Показать аудит",
  work_schedule: "График работы",
  specialities: "Специальности",
  new_specialities: "Новая специальность",
  update_specialities: "Изменить специальность",
  rooms: "Комнаты",
  new_room: "Новая комната",
  services: "Услуги",
  service: "Услуга",
  new_service: "Новая услуга",
  update_service: "Изменить услугу",
  partner_doctor: "Партнер доктор",
  new_partner_doctor: "Новый партнер доктор",
  update_partner_doctor: "Изменить партнера доктора",
  time: "Время",
  times: "Время",
  new_time: " Новое время",
  update_time: " Изменить время",
  condition: "Состояние",
  organization: "Организация",
  countries: "Страны",
  country: "Страна",
  new_country: "Новая страна",
  update_country: "Изменить страну",
  cities: "Города",
  city: "Город",
  type_documents: "Тип документа",
  documents: "Документ",
  series_and_number: "Серия и номер",
  authority: "Орган выдачи",
  date_of_issue: "Дата выпуска",
  citizenship: "Гражданство",
  nationality: "Национальность",
  add_nationality: "Добавить национальность",
  update_nationality: "Изменить национальность",
  region: "Область (Регион)",
  add_region: "Добавить Область (Регион)",
  update_region: " Изменить Область (Регион)",
  district: "Район",
  street: "Улица",
  house: "Дом",
  flat: "Квартира",
  photo: "Фотография(3x4 см)",
  new_photo: "Добавить изображение",
  add_document: "Добавить документ",
  blank_head: "Шапка от бланка",
  do_you_want: "Внимание Вы действительно хотите это сделать?",
  do_you_want_confirm: "Вы действительно хотите подтвердить ",
  category_mkb: "Категории МКБ",
  my_patients: "Мои пациенты",
  my_conclusions: "Мои заключения",
  all_conclusions: "Все заключения",
  conclusions: "Заключения",
  patient_conclusions: "Заключение пациента",
  write_conclusions: "Написать заключение",
  show_conclusions: "Показать заключение",
  print_center: "Центр печати",
  print_hitory: "История печати",
  procurement: "Закупки",
  movement: "Перемещение",
  add_movement: "Добавить перемещение",
  update_movement: "Изменить перемещение",
  write_offs: "Списание",
  remainder: "Остаток",
  remainders: "Остатки",
  products: "Продукты",
  product: "Продукт",
  groups: "Группы",
  new_groups: "Новая группа",
  group: "Группа",
  slug: "Код",
  measurements: "Ед. изм",
  measurement_full: "Ед. измерения",
  new_measurements: "Новая ед. изм.",
  suppliers: "Поставщики",
  suppliers_aggrement: "Договора поставщиков",
  status: "Статусы",
  status_0: "Статус",
  medicines_on: "Лекарства Н/О",
  nurse_office: "Кабинет медсестры",
  phy_office: "Кабинет физиотерапии",
  purchasing_products: "Закупка продукции",
  forms_mkb: "Формы (МКБ-10)",
  schedule_cabinet: "Расписание кабинета",
  active_patients: "Активные пациенты",
  patients2: "Пациенты",
  transactions: "Транзакции",
  week: "Неделя",
  month: "Месяц",
  year: "Год",
  expences: "Расходы",
  expence: "Расход",
  speciality: "Специальность",
  chose_a_speciality: "Выберите специальность",
  reception_visit: "Визит на прием",
  quantity: "Количество",
  total_amount: "Общая сумма",
  print: "Печать",
  patientServiceReport: "Отчет по услугам",
  patientResidenceReport: "Отчет по районам",
  print_double: "Печать 2x",
  printing: "Распечатать",
  save: "Сохранить",
  create_new_patient: "Создать нового пациента",
  close: "Закрыть",
  basic_information: "Основная информация",
  add_new_patient: "Добавить нового пациента",
  update_new_patient: "Изменить нового пациента",
  from_hours: "От часов",
  hour: "Часов",
  duration: "Продолжительность",
  lasting: "Длительность",
  minut: "мин.",
  notes: "Заметки",
  note: "Заметка",
  cabinet: "Кабинет",
  contracts_n: "Договора №",
  without_calculation: "Договора №",
  select_patient: "Выберите пациента",
  select_patient_or_find: "Выберите пациента слева или добавьте нового",
  start_input_find_service: "Начните вводить наименование или артикуль услуги",
  add: "Добавить",
  add_service: "Добавить услугу",
  add_new_service: "Добавить новую услугу",
  subject_of_agreement: "Предмет договора (Услуги)",
  quantity_short: "Кол-во",
  price2: "Цена",
  discount_percent: "Скидка %",
  discount_price: "Скидка (Сумм)",
  discount: "Скидка",
  discounts: "Скидки",
  amount: "Сумма",
  delete: "Удалить",
  add_new_partner_doctor: "Добавить нового партнера доктора",
  add_partner_doctor: "Добавить партнера доктора",
  total: "Итог",
  totals: "Итоги",
  total_0: "Итог",
  from_whom: "От кого",
  login: "Авторизация",
  sign_in: "Войти в систему",
  hospitalization: "Госпитализация",
  open: "Открыть",
  lend: "В долг",
  without_amount: "Без расчета",
  add_to_room: "Запись в палату",
  add_to_reception: "Запись на прием",
  bunk: "Койка",
  create: "Создать",
  class: "Класс",
  branch: "Отделение",
  department: "Отдел",
  dapartment_user: "Пользователи отдела",
  floor: "Этаж",
  hospitalization_type: "Тип госпитализации",
  room: "Комната",
  corps: "Корпус",
  purpose_of_hospitalization: "Цель госпитализации",
  type_of_injury: "Вид травмы",
  type_condition: "Вид состояния",
  conditions: "Cостояние",
  patient_indicator: "Показатель пациента",
  indicator: "Показатель",
  indicators: "Показатели",
  add_bunk: "Добавить койку",
  add_new_bunk: "Добавить новую койку",
  save_and_close: "Сохранить и закрыть",
  add_class: "Добавить класс",
  price_per_day: "Цена за сутки",
  users: "Пользователи",
  add_department_user: "Добавить пользователя отдела",
  add_branch: "Добавить отделение",
  code_id: "Код ID",
  add_floor: "Добавить этаж",
  add_corps: "Добавить корпус",
  add_type_of_injury: "Добавить вид травмы",
  patient_indicators: "Показатели пациента",
  add_patient_indicators: "Добавить показатели пациента",
  add_purpose_of_hospitalization: "Добавить цель госпитализации",
  add_room: "Добавить комнату",
  add_conditions: "Добавить состояние",
  add_hospitalization_type: "Добавить тип госпитализации",
  update_bunk: "Изменить койку",
  update_class: "Изменить класс",
  update_branch: "Изменить отделение",
  update_floor: "Изменить этаж",
  update_corps: "Изменить корпус",
  update_type_of_injury: "Изменить вид травмы",
  update_patient_indicators: "Изменить показатели пациента",
  update_purpose_of_hospitalization: "Изменить цель госпитализации",
  update_room: "Изменить комнату",
  update_conditions: "Изменить состояние",
  update_hospitalization_type: "Изменить тип госпитализации",
  update_department_user: "Изменить пользователя отдела",
  buy_ready_product: "Закупка продукции",
  delivery_deadline: "Дата поставки",
  total_cost: "Общая стоимость",
  description: "Описание",
  receive: "Приход",
  new_aggrement: "Новый договор",
  number_aggrement: "Номер договора",
  date_aggrement: "Дата договора",
  sum: "Сумма",
  new_product: "Новый продукт",
  username: "Владелец:",
  update_product: "Изменить продукт",
  new_supplier: "Новый поставщик",
  current_price: "Действующая цена",
  discount_cost: "Скидочная цена",
  category_or_service: "Категория или услуга",
  parent_service: "Родительские услуги",
  raw_producement: "Закупка сырья",
  qty_come: "Кол. прихода",
  sale_percentage: "Процент продажи",
  sale_price: "Цена продажи",
  date_produced: "Дата производства",
  term: "Срок годности",
  date_receiving: "Дата получения",
  new_status: "Новый статус",
  add_order_payment: "Добавить заказ платежей",
  order_payment: "Заказ платежей",
  add_transaction: "Добавить транзакцию",
  history_transaction: "История транзакций",
  new_category_service: "Новая категория услуги",
  update_category_service: "Изменить категорию услуги",
  create_citizenship: "Добавить гражданство",
  update_citizenship: "Изменить гражданство",
  create_city: "Новый город",
  update_city: "Изменить город",
  create_condition: "Новое состояние",
  update_condition: "Изменить состояние",
  contract_parnet: "Гл. договор №",
  contract_payment: "Платежи по договору",
  info_aggrement: "Информация о договоре",
  contact_service: "Контактные услуги",
  create_currency: "Новая валюта",
  update_currency: "Изменить валюту",
  order_service: "Заказать услугу",
  placeholder_time_date: "Выберите дату и время",
  search_template: "Найти шаблон",
  choose_template: "Выбор шаблона",
  new_type_document: "Новый тип документа",
  update_type_document: "Изменить тип документа",
  new_patient: "Добавить пациента",
  patient_history: "История пациентов",
  patient_histories: "История пациентов",
  condition_patient: "Состояние пациента",
  physiotherapy: "Физиотерапия",
  bed_schedules: "Расписание койек",
  stationary_settings: "Настройки стационара",
  cargo_storage: "Хранение груза",
  hospitalization_complated: "Госпитализация окончена",
  complated: "Выполнено",
  drug_instruction: "Лекарство / Инструкция",
  info_treatment: "Информация для лечения",
  how_prepare_medicine: "Как приготовить лекарства",
  nurse_wrong_choice: "Медсестра ошиблась в выборе клеток",
  done_treatment: "Сделано (препарат успешно введен)",
  patient_reject: "Пациент отказал/а",
  drug_bad_reaction: "Дефекты (После введения препарата, была реакция)",
  drug_good_reaction: "Далее (Реакция не от этого препарата продолжайте процедуру)",
  company_name: "Название компании",
  update_organization: "Изменить организацию",
  create_organization: "Новая организация",
  update_patient: "Изменить пациента",
  order_count: "Количество заказов",
  count: "Количество",
  group_of_patient: "Возрастные группы пациентов",
  create_hospitalization: "Добавить госпитализацию",
  update_hospitalization: "Изменить госпитализацию",
  date_hospitalization: "Дата госпитализации",
  diagnos: "Предварительный диагноз",
  blood: "Группа крови",
  temperature: "Температура",
  puls: "А Д",
  height: "Рост",
  weight: "Вес",
  allergy: "Аллергия",
  date_injury: "Дата заболевания",
  date_register: "Дата регистрации",
  direction: "Направление",
  dailyTreatment: "Ежедневный показатель",
  cabinat_balance: "Баланс клиента",
  patient_balance: "Баланс пациента",
  dailyTreatment_patient: "Ежедневные показатели пациента",
  add_medicine: "Добавить лекарства",
  patient_hospitalization: "Госпитализация пациента",
  patient_report: "Все заключения пациента",
  all_service_patient: "Все услуги пациента",
  patient_physiotherapy: "Физиотерапия пациента",
  add_patient_physiotherapy: "Добавить физиотерапию пациенту",
  add_physiotherapy: "Добавить физиотерапию",
  drug_indicator_warehouse: "Назначение лекарства (склад)",
  drug_indicator: "Назначение лекарства",
  add_drug_indicator: "Добавить назначение лекарства",
  add_drug_indicator_warehouse: "Добавить назначение лекарства (склад)",
  instruction_for_use: "Инструкция по применению",
  period: "Период",
  purpose_quantity: "Количество назначений",
  medicines: "Лекарственные средства",
  how_many_days: "Сколько дней",
  money_per_day: "Цена за день",
  change: "Заменить",
  select_time: "Выберите дату и время",
  enter_id: "Введите идентификатор (№)",
  patient_or_fio: "Пациент № или ФИО",
  update_teg: "Изменить тег",
  print_conclusion: "Распечатать заключение",
  medical_history: "История болезней",
  product_detail: "Детали продукта",
  add_unit_of_measure: "Добавить единицу измерения",
  filter_keyword: "Ключевое слово в фильтре",
  filter: "Фильтры",
  busy: "Занято",
  free: "Свободно",
  booked: "Забронировано",
  quarantine: "Карантин",
  choose_category: "Выберите категорию",
  title: "заголовок",
  running_title: "колонтитул",
  page_header: "Верхний колонтитул",
  footer_header: "Нижний колонтитул",
  content: "Контент",
  income_product: "Входящие продукты",
  materials_consuption: "Расход материала",
  detail: "Детали",
  new_warehouse: "Новый склад",
  new_user: "Новый пользователь",
  please_enter_parol: "Пожалуйста, введите пароль",
  please_choose_role: "Пожалуйста, выберите роль",
  search_doctor: "поиск врача",
  all_speciality: "Все специальности",
  speciality_doctor: "специальность врача",
  password: "Пароль",
  id_code: "Идентификационный код",
  last_login: "Последний вход",
  category: "категория",
  recipient: "Получатель",
  used: "Используемый",
  phone_two: "Телефон 2",
  doctor_service: "Услуги врача",
  locked: "Заблокировано",
  manufactured_date: "дата изготовления",
  percent: "% продажи",
  percent_: "% ",
  new_price: "Цена за одно количество",
  from_time: "Начальное время",
  to_time: "Время окончания",
  write_off_date: "Дата списания",
  transmitter: "Отправитель",
  write_off_products: "Списание продуктов",
  write_of_from: "Дата списания от",
  delivery_time: "Срок поставки",
  change_write_off: "Изменение списания",
  contract_parent: "Гл. договор №",
  order_amount: "Сумма обслуживания",
  drive_license: "Водительское удостоверение",
  pieces: "шт.",
  update_setting: "Изменить",
  delete_setting: "Удалить",
  adds: "Добавить",
  procedural: "Процедурная",
  remainder_reports: "Отчет процедурная",
  invalid: "Брак",
  valid: "Медикаменты",
  used_medicine: "Используемый процедурная",
  loading: "загрузка",
  medicine: "Продукты",
  age: "Возраст",
  laboratory: "Лаборатория",
  percentage: "Процент",
  laboratory: "Лаборатория",
  normaLaboratory: "Норма Лаборатория",
  normaCost: "Норма расходов",
  resourcesText: "Ресурсы",
  conResourcesText: "Под ресурсов",
  patient_age_reports: "Возраст отчеты",
  express: "Экспресс",
  expresses: "Экспресс",
  processes: "Процессы",
  process: "Процесс",
  add_processes: "Добавить Процесс",
  add_processes: "Добавить Процесс",
  responsible_name: "Представитель",
  material: "Материал",
  add_new_partner_clinic: "Добавить новую клинику",
  add_partner_clinic: "Добавить партнера клиника",
  report_partner_clinics: "Отчет по партнёрам клиникам",
  partner_clinic: "Партнер клиника",
  partner_clinics: "Партнер клиники",
  new_partner_clinic: "Новый партнер клиника",
  update_partner_clinic: "Изменить партнера клиника",
  order_id: "ID заказа",
  patient_id: "ID пациента",
  lifetime: "Срок",
  life_time: "Срок",
  order_created_at: "Дата приема заказа",
  order_updated_at: "Дата исполнения",
  expired: "Просроченные",
  dailyProcessReport: "Отчет по процессам",
  my_laboratory: "Лаборатория",
  laborant: "Лаборант",
  receive_: "Принять",
  my_informal_laboratory_patients: "Заказы лаборатория",
  my_informal_patients: "Заказы врачи",
  bonus: "Бонус",
  bonus_amount: "Бонус",
  used_cassettes: "Использованных кассет",
  used_glasses: "Использованных стекол",
  paymentBonusP: "Бонусные Платежи",
  doctorProcessReport: "Общий отчет по процессам",
  all_laboratory_conclusions: "Лабораторные заключения",
  new_daily_process_report: "Новый отчет по процессам",
  reason: "Причина",
  new_report: "Новый отчет",
  update_report: "Изменить отчет",
  scores: "Счета",
  incoming: "Входящие",
  outgoing: "Исходящий",
  score: " Счета",
  bonus_paid_amount: "Выплачено",
  bonus_debt: "Остаток",
  excelReport: 'Отчет Excel',
  sickNumber: 'МКБ-код',
  add_new_department: "Добавить новый отдел",
  new_order: "Новый заказ",
  new_discount: "Новый скидка",
  update_discount: "Изменить скидка",
  glass: 'Стекла',
  add_new_doctor_sign: "Добавить новую подпись",
  update_doctor_sign: "Изменить подпись",
  doctorSign: 'Подпись',
  doctorSigns: 'Подписи',
  outpatient_card: 'Амбулаторная карта',
  letterhead: 'Партнерская',
  today_transactions: 'Общая сумма за сегодня',
  payment: "Платеж",
  refund: "Возврат",
  transactionScores: 'Баланс',
  orderServiceTerm: 'Заказы по датам',
  typebonus: "Тип Бонус",
  country_code: "Код страны",
  tel_number: "Номер телефона",
  exemple: "Пример :",
  validation: "Ошибка",
  phone_number_is_not_valid: "Этот номер телефона не соответствует",
  invoice: 'Счет-фактуры',
  order_date: 'Дата заказа',
  conclusion_date: 'Дата заключения',
  conclusion_date: 'Дата заключения',
  partner_amount: 'Партнер сумма',
  id_number: "ID заказа",
  number_of_orders_today: 'Количество заказов сегодня',
  number_of_orders_month: 'Количество заказов за месяц',
  is_close: 'Заключение утверждено',
  own_doctor: 'Наши доктор',
  process_l: 'Процессы Л.П.Д',
  status_waiting_paid: 'Ждет оплаты',
  status_laborant_free: 'Заказы лаборатории',
  status_laborant: 'У лаборанта',
  status_doctor_free: 'Заказы врачи',
  status_doctor: 'У врача',
  status_written: 'Заключение написано',
  status_closed: 'Успешно',

  pp: 'П.П.',
  ifo: 'И.Ф.О',
  service_name: 'Название услуги',
  schedule: 'Расписание',
  patient: 'Пациент',
  patient_service: 'Услуги пациента',
  patient_info: 'Информация о пациенте',
  patient_0: 'Пациент',
  patients: 'Пациенты',
  reports: 'Отчеты',
  cashbox: 'Касса',
  warehouses: 'Склады',
  actions: 'Действия',
  settings: 'Настройки',
  forms: 'Формы (МКБ-10)',
  my_office: 'Мой Кабинет',
  hospital: 'Стационар',
  printing: 'Печать',
  log_out: 'Выйти',
  profile: 'Профиль',
  print_form: 'Печатная форма',
  forms_of_documents: 'Формы документов',
  document_forms_editor: 'Редактировать формы документов',
  source: 'Источник',
  new_source: 'Новый источник',
  update_source: 'Изменить источник',
  tags: 'Теги',
  new_tag: 'Новый тег',
  update_tags: 'Изменить тег',
  service_categoy: 'Категория услуги',
  branches: 'Филиалы',
  orders: 'Заказы',
  order: 'Заказ',
  conclusions_history: 'История заключений',
  report_doctors: 'Отчет-врачи',
  report_partner_doctors: 'Отчет по партнёрам докторов',
  payments: 'Платежи',
  contracts: 'Договора',
  payment_reports: 'Отчёты платежей',
  payments_for_expenses: 'Платежи за расходы',
  currency: 'Валюта',
  payment_type: 'Тип оплаты',
  new_payment_type: 'Новый тип оплаты',
  update_payment_type: 'Изменить тип оплаты',
  flow_types: 'Типы расхода',
  stasionary_cashier: 'Касса стационара',
  users: 'Пользователи',
  user: 'Пользователь',
  roles: 'Роли',
  role: 'Роль',
  role_name: 'Имя роли',
  update_role: 'Изменить роль',
  new_role: 'Новая роль',
  access_rights: 'Права доступа',
  access_right: 'Права доступа',
  update_access_rights: 'Изменить права доступа',
  choose_status: 'пожалуйста, выберите статус',
  print_audit: 'Печатный аудит',
  show_audit: 'Показать аудит',
  work_schedule: 'График работы',
  specialities: 'Специальности',
  new_specialities: 'Новая специальность',
  update_specialities: 'Изменить специальность',
  rooms: 'Комнаты',
  new_room: 'Новая комната',
  services: 'Услуги',
  service: 'Услуга',
  new_service: 'Новая услуга',
  update_service: 'Изменить услугу',
  partner_doctor: 'Партнер доктор',
  new_partner_doctor: 'Новый партнер доктор',
  update_partner_doctor: 'Изменить партнера доктора',
  time: 'Время',
  times: 'Время',
  new_time: ' Новое время',
  update_time: ' Изменить время',
  condition: 'Состояние',
  organization: 'Организация',
  countries: 'Страны',
  country: 'Страна',
  new_country: 'Новая страна',
  update_country: 'Изменить страну',
  cities: 'Города',
  city: 'Город',
  type_documents: 'Тип документа',
  documents: 'Документ',
  series_and_number: 'Серия и номер',
  authority: 'Орган выдачи',
  date_of_issue: 'Дата выпуска',
  citizenship: 'Гражданство',
  nationality: 'Национальность',
  add_nationality: 'Добавить национальность',
  update_nationality: 'Изменить национальность',
  region: 'Регион',
  add_region: 'Добавить регион',
  update_region: ' Изменить регион',
  district: 'Район',
  street: 'Улица',
  house: 'Дом',
  flat: 'Квартира',
  photo: 'Фотография(3x4 см)',
  new_photo: 'Добавить изображение',
  add_document: 'Добавить документ',
  blank_head: 'Шапка от бланка',
  do_you_want: 'Внимание Вы действительно хотите это сделать?',
  do_you_want_confirm: 'Вы действительно хотите подтвердить ',
  category_mkb: 'Категории МКБ',
  my_patients: 'Мои пациенты',
  my_conclusions: 'Мои заключения',
  all_conclusions: 'Все заключения',
  conclusions: 'Заключения',
  patient_conclusions: 'Заключение пациента',
  write_conclusions: 'Написать заключение',
  show_conclusions: 'Показать заключение',
  print_center: 'Центр печати',
  print_hitory: 'История печати',
  procurement: 'Закупки',
  movement: 'Перемещение',
  add_movement: 'Добавить перемещение',
  update_movement: 'Изменить перемещение',
  write_offs: 'Списание',
  remainder: 'Остаток',
  remainders: 'Остатки',
  products: 'Продукты',
  product: 'Продукт',
  groups: 'Группы',
  new_groups: 'Новая группа',
  group: 'Группа',
  slug: 'Код',
  measurements: 'Ед. изм',
  measurement_full: 'Ед. измерения',
  new_measurements: 'Новая ед. изм.',
  suppliers: 'Поставщики',
  suppliers_aggrement: 'Договора поставщиков',
  status: 'Статусы',
  status_0: 'Статус',
  medicines_on: 'Лекарства Н/О',
  nurse_office: 'Кабинет медсестры',
  phy_office: 'Кабинет физиотерапии',
  purchasing_products: 'Закупка продукции',
  forms_mkb: 'Формы (МКБ-10)',
  schedule_cabinet: 'Расписание кабинета',
  active_patients: 'Активные пациенты',
  patients2: 'Пациенты',
  transactions: 'Транзакции',
  week: 'Неделя',
  month: 'Месяц',
  year: 'Год',
  expences: 'Расходы',
  speciality: 'Специальность',
  chose_a_speciality: 'Выберите специальность',
  reception_visit: 'Визит на прием',
  quantity: 'Количество',
  total_amount: 'Общая сумма',
  print: 'Печать',
  printing: 'Распечатать',
  save: 'Сохранить',
  create_new_patient: 'Создать нового пациента',
  create_new_order: 'Создать новый заказ',
  close: 'Закрыть',
  basic_information: 'Основная информация',
  add_new_patient: 'Добавить нового пациента',
  update_new_patient: 'Изменить нового пациента',
  from_hours: 'От часов',
  duration: 'Продолжительность',
  lasting: 'Длительность',
  minut: 'мин.',
  notes: 'Заметки',
  note: 'Заметка',
  cabinet: 'Кабинет',
  contracts_n: 'Договора №',
  without_calculation: 'Договора №',
  select_patient: 'Выберите пациента',
  select_patient_or_find: 'Выберите пациента слева или добавьте нового',
  start_input_find_service: 'Начните вводить наименование или артикуль услуги',
  add: 'Добавить',
  add_service: 'Добавить услугу',
  add_new_service: 'Добавить новую услугу',
  subject_of_agreement: 'Предмет договора (Услуги)',
  quantity_short: 'Кол-во',
  price2: 'Цена',
  discount_percent: 'Скидка %',
  discount_price: 'Скидка (Сумм)',
  discount: 'Скидка',
  discounts: 'Скидки',
  amount: 'Сумма',
  delete: 'Удалить',
  add_new_partner_doctor: 'Добавить нового партнера доктора',
  add_partner_doctor: 'Добавить партнера доктора',
  total: 'Итог',
  totals: 'Итоги',
  total_0: 'Итог',
  from_whom: 'От кого',
  login: 'Авторизация',
  sign_in: 'Войти в систему',
  hospitalization: 'Госпитализация',
  open: 'Открыть',
  lend: 'В долг',
  without_amount: 'Без расчета',
  add_to_room: 'Запись в палату',
  add_to_reception: 'Запись на прием',
  bunk: 'Койка',
  create: 'Создать',
  class: 'Класс',
  branch: 'Отделение',
  department: 'Отдел',
  dapartment_user: 'Пользователи отдела',
  floor: 'Этаж',
  hospitalization_type: 'Тип госпитализации',
  room: 'Комната',
  corps: 'Корпус',
  purpose_of_hospitalization: 'Цель госпитализации',
  type_of_injury: 'Вид травмы',
  type_condition: 'Вид состояния',
  conditions: 'Cостояние',
  patient_indicator: 'Показатель пациента',
  indicator: 'Показатель',
  indicators: 'Показатели',
  add_bunk: 'Добавить койку',
  add_new_bunk: 'Добавить новую койку',
  save_and_close: 'Сохранить и закрыть',
  add_class: 'Добавить класс',
  price_per_day: 'Цена за сутки',
  users: 'Пользователи',
  add_department_user: 'Добавить пользователя отдела',
  add_branch: 'Добавить отделение',
  code_id: 'Код ID',
  add_floor: 'Добавить этаж',
  add_corps: 'Добавить корпус',
  add_type_of_injury: 'Добавить вид травмы',
  patient_indicators: 'Показатели пациента',
  add_patient_indicators: 'Добавить показатели пациента',
  add_purpose_of_hospitalization: 'Добавить цель госпитализации',
  add_room: 'Добавить комнату',
  add_conditions: 'Добавить состояние',
  add_hospitalization_type: 'Добавить тип госпитализации',
  update_bunk: 'Изменить койку',
  update_class: 'Изменить класс',
  update_branch: 'Изменить отделение',
  update_floor: 'Изменить этаж',
  update_corps: 'Изменить корпус',
  update_type_of_injury: 'Изменить вид травмы',
  update_patient_indicators: 'Изменить показатели пациента',
  update_purpose_of_hospitalization: 'Изменить цель госпитализации',
  update_room: 'Изменить комнату',
  update_conditions: 'Изменить состояние',
  update_hospitalization_type: 'Изменить тип госпитализации',
  update_department_user: 'Изменить пользователя отдела',
  buy_ready_product: 'Закупка продукции',
  delivery_deadline: 'Дата поставки',
  total_cost: 'Общая стоимость',
  description: 'Описание',
  receive: 'Приход',
  new_aggrement: 'Новый договор',
  number_aggrement: 'Номер договора',
  date_aggrement: 'Дата договора',
  sum: 'Сумма',
  new_product: 'Новый продукт',
  username: 'Владелец:',
  update_product: 'Изменить продукт',
  new_supplier: 'Новый поставщик',
  current_price: 'Действующая цена',
  discount_cost: 'Скидочная цена',
  category_or_service: 'Категория или услуга',
  parent_service: 'Родительские услуги',
  raw_producement: 'Закупка сырья',
  qty_come: 'Кол. прихода',
  sale_percentage: 'Процент продажи',
  sale_price: 'Цена продажи',
  date_produced: 'Дата производства',
  term: 'Срок годности',
  date_receiving: 'Дата получения',
  new_status: 'Новый статус',
  add_order_payment: 'Добавить заказ платежей',
  order_payment: 'Заказ платежей',
  add_transaction: 'Добавить транзакцию',
  history_transaction: 'История транзакций',
  new_category_service: 'Новая категория услуги',
  update_category_service: 'Изменить категорию услуги',
  create_citizenship: 'Добавить гражданство',
  update_citizenship: 'Изменить гражданство',
  create_city: 'Новый город',
  update_city: 'Изменить город',
  create_condition: 'Новое состояние',
  update_condition: 'Изменить состояние',
  contract_parnet: 'Гл. договор №',
  contract_payment: 'Платежи по договору',
  info_aggrement: 'Информация о договоре',
  contact_service: 'Контактные услуги',
  create_currency: 'Новая валюта',
  update_currency: 'Изменить валюту',
  order_service: 'Заказать услугу',
  placeholder_time_date: 'Выберите дату и время',
  search_template: 'Найти шаблон',
  choose_template: 'Выбор шаблона',
  new_type_document: 'Новый тип документа',
  update_type_document: 'Изменить тип документа',
  new_patient: 'Добавить пациента',
  patient_history: 'История пациентов',
  patient_histories: 'История пациентов',
  condition_patient: 'Состояние пациента',
  physiotherapy: 'Физиотерапия',
  bed_schedules: 'Расписание койек',
  stationary_settings: 'Настройки стационара',
  cargo_storage: 'Хранение груза',
  hospitalization_complated: 'Госпитализация окончена',
  complated: 'Выполнено',
  drug_instruction: 'Лекарство / Инструкция',
  info_treatment: 'Информация для лечения',
  how_prepare_medicine: 'Как приготовить лекарства',
  nurse_wrong_choice: 'Медсестра ошиблась в выборе клеток',
  done_treatment: 'Сделано (препарат успешно введен)',
  patient_reject: 'Пациент отказал/а',
  drug_bad_reaction: 'Дефекты (После введения препарата, была реакция)',
  drug_good_reaction: 'Далее (Реакция не от этого препарата продолжайте процедуру)',
  company_name: 'Название компании',
  update_organization: 'Изменить организацию',
  create_organization: 'Новая организация',
  update_patient: 'Изменить пациента',
  order_count: 'Количество заказов',
  group_of_patient: 'Возрастные группы пациентов',
  create_hospitalization: 'Добавить госпитализацию',
  update_hospitalization: 'Изменить госпитализацию',
  date_hospitalization: 'Дата госпитализации',
  diagnos: 'Предварительный диагноз',
  blood: 'Группа крови',
  temperature: 'Температура',
  puls: 'А Д',
  height: 'Рост',
  weight: 'Вес',
  allergy: 'Аллергия',
  date_injury: 'Дата заболевания',
  date_register: 'Дата регистрации',
  direction: 'Направление',
  dailyTreatment: 'Ежедневный показатель',
  cabinat_balance: 'Баланс клиента',
  patient_balance: 'Баланс пациента',
  dailyTreatment_patient: 'Ежедневные показатели пациента',
  add_medicine: 'Добавить лекарства',
  patient_hospitalization: 'Госпитализация пациента',
  patient_report: 'Все заключения пациента',
  all_service_patient: 'Все услуги пациента',
  patient_physiotherapy: 'Физиотерапия пациента',
  add_patient_physiotherapy: 'Добавить физиотерапию пациенту',
  add_physiotherapy: 'Добавить физиотерапию',
  drug_indicator_warehouse: 'Назначение лекарства (склад)',
  drug_indicator: 'Назначение лекарства',
  add_drug_indicator: 'Добавить назначение лекарства',
  add_drug_indicator_warehouse: 'Добавить назначение лекарства (склад)',
  instruction_for_use: 'Инструкция по применению',
  period: 'Период',
  purpose_quantity: 'Количество назначений',
  medicines: 'Лекарственные средства',
  how_many_days: 'Сколько дней',
  money_per_day: 'Цена за день',
  change: 'Заменить',
  select_time: 'Выберите дату и время',
  enter_id: 'Введите идентификатор (№)',
  patient_or_fio: 'Пациент № или ФИО',
  update_teg: 'Изменить тег',
  print_conclusion: 'Распечатать заключение',
  medical_history: 'История болезней',
  product_detail: 'Детали продукта',
  add_unit_of_measure: 'Добавить единицу измерения',
  filter_keyword: 'Ключевое слово в фильтре',
  filter: 'Фильтры',
  busy: 'Занято',
  free: 'Свободно',
  booked: 'Забронировано',
  quarantine: 'Карантин',
  choose_category: 'Выберите категорию',
  title: 'заголовок',
  running_title: 'колонтитул',
  page_header: 'Верхний колонтитул',
  footer_header: 'Нижний колонтитул',
  content: 'Контент',
  income_product: 'Входящие продукты',
  materials_consuption: 'Расход материала',
  detail: 'Детали',
  new_warehouse: 'Новый склад',
  new_user: 'Новый пользователь',
  please_enter_parol: 'Пожалуйста, введите пароль',
  please_choose_role: 'Пожалуйста, выберите роль',
  search_doctor: 'поиск врача',
  all_speciality: 'Все специальности',
  speciality_doctor: "специальность врача",
  password: 'Пароль',
  id_code: 'Идентификационный код',
  last_login: 'Последний вход',
  category: 'категория',
  recipient: 'Получатель',
  used: 'Используемый',
  phone_two: 'Телефон 2',
  doctor_service: 'Услуги врача',
  locked: 'Заблокировано',
  manufactured_date: 'дата изготовления',
  percent: '% продажи',
  new_price: 'Цена за одно количество',
  from_time: 'Начальное время',
  to_time: 'Время окончания',
  write_off_date: 'Дата списания',
  transmitter: 'Отправитель',
  write_off_products: 'Списание продуктов',
  write_of_from: 'Дата списания от',
  delivery_time: 'Срок поставки',
  change_write_off: 'Изменение списания',
  contract_parent: 'Гл. договор №',
  order_amount: 'Сумма обслуживания',
  drive_license: 'Водительское удостоверение',
  pieces: 'шт.',
  update_setting: 'Изменить',
  delete_setting: 'Удалить',
  adds: 'Добавить',
  procedural: 'Процедурная',
  remainder_reports: 'Отчет процедурная',
  invalid: "Брак",
  valid: "Медикаменты",
  used_medicine: "Используемый процедурная",
  loading: "загрузка",
  medicine: "Продукты",
  age: "Возраст",
  laboratory: "Лаборатория",
  percentage: "Процент",
  laboratory: "Лаборатория",
  normaLaboratory: "Норма Лаборатория",
  normaCost: "Норма расходов",
  resourcesText: "Ресурсы",
  conResourcesText: "Под ресурсов",
  patient_age_reports: "Возраст отчеты",
  owner_clinic: 'Брендинг',
  new_owner_clinic: 'Новый Брендинг',
  web_site: "Веб-сайт",
  phone_numbers: "Номер телефоны",
  logo: "Логотип",
  type_phone: 'Тип телефона',

};
