import { i18n } from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    address: {
        show: true,
        title: i18n.t('message.address'),
        sortable: true,
        column: 'address'
    },
    web_site: {
        show: true,
        title: i18n.t('message.web_site'),
        sortable: true,
        column: 'web_site'
    },
    phone_number: {
        show: true,
        title: i18n.t('message.phone_number'),
        sortable: true,
        column: 'phone_number'
    },

    email: {
        show: false,
        title: i18n.t('message.email'),
        sortable: true,
        column: 'email'
    },
    image: {
        show: false,
        title: i18n.t('message.logo'),
        sortable: true,
        column: 'image'
    },
};